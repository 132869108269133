import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Order food online in Brighton! It's so easy to use, fast and convenient.
        Try our new, online website which contains our entire takeaway menu. Bua
        Thai is located in Brighton.
      </Typography>
      <Typography variant="body1" paragraph>
        You can now order online, all your favourite dishes and many more
        delicious options, and have them delivered straight to your door in no
        time at all.
      </Typography>
      <Typography variant="body1" paragraph>
        Here at Bua Thai we are constantly striving to improve our service and
        quality in order to give our customers the very best experience. As a
        result, we are finally proud to unveil and introduce our latest
        improvement, our new online ordering website! You can now relax at home
        and order your favourite, freshly prepared meals from Bua Thai, online.
        You can even pay online!
      </Typography>
      <Typography variant="body1" paragraph>
        Bua Thai in Brighton will always be offering great food at affordable
        prices. Please feel free to browse our new website and place your order
        online. Remember to check our new online ordering site to get up to date
        prices and exclusive special offers, limited to our online customers
        only!
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for visiting Bua Thai in Brighton. We hope you enjoy our
        online ordering website and your food.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
